import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import ContactBlock from "../components/Contact/ContactBlock"

const ContactPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data

  const { metaTitle, metaDescription, email, content } = page

  return (
    <Layout isFooter={false} hero={"true"} twosided={"true"}>
      <SEO
        title={metaTitle}
        description={
          metaDescription
        }
        path={path}
      />
      <ContactBlock
        content={content}
        email={email}
      />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    page: contentfulContactPage {
      metaTitle
      metaDescription
      email
      content {
        json
      }
    }
  }
`
