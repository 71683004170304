import React, { Component } from "react"
import styled from 'styled-components';
import breakpoints from "../../styles/breakpoints"
import Button from "../Core/Button"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const FormBlock = styled.div`
    padding: 80px 33px 83px;
    @media(min-width: ${breakpoints.lg}){
        padding: 0;
    }
`;

const InputStyled = styled.input`
    font-size: 16px;
    color: ${colors.grey};
    line-height: 24px;
    ${fonts.avantGardrLight};
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid ${colors.mediumGrey};
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 7px;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${colors.grey};
      ${fonts.avantGardrLight};
      font-size: 16px;
      line-height: 24px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: ${colors.grey};
      ${fonts.avantGardrLight};
      font-size: 16px;
      line-height: 24px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: ${colors.grey};
      ${fonts.avantGardrLight};
      font-size: 16px;
      line-height: 24px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: ${colors.grey};
      ${fonts.avantGardrLight};
      font-size: 16px;
      line-height: 24px;
    }
    placeholder { 
      color: ${colors.grey};
      ${fonts.avantGardrLight};
      font-size: 16px;
      line-height: 24px;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover, 
    :-webkit-autofill:focus, 
    :-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 65px ${colors.white} inset !important;
      ${fonts.avantGardrLight} !important;
      font-size: 16px;
      line-height: 24px;
    }
    :-webkit-autofill {
      -webkit-text-fill-color: ${colors.grey} !important;
      ${fonts.avantGardrLight};
      color: ${colors.grey};
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 45px;
`;

const Required = styled.div`
    ${fonts.avantGardrLight};
    font-size: 12px;
    color: ${colors.grey};
    letter-spacing: 0;
    line-height: 24px;
    padding-right: 10px;
`;

const Textarea = styled.textarea`
    resize: none;
    width: 100%;
    font-size: 16px;
    color: ${colors.grey};
    ${fonts.avantGardrLight};
    border: none;
    outline: none;
    min-height: 140px;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
    box-shadow: none;
    background-attachment: local;
    background-image:
        linear-gradient(to right, white 0px, transparent 0px),
        linear-gradient(to left, white 0px, transparent 0px),
        repeating-linear-gradient(white, white 44px, #E7E7E7 44px, #E7E7E7 45px, white 45px);
    line-height: 48px;
`;

const ThankYou = styled.div`
    ${fonts.avantGardeBold};
    color: ${colors.blue};
    padding-bottom: 10px;
    font-size: 18px;
`;

const initialState = {
  name: '',
  email: '',
  company: '',
  message: '',
  formSubmitted: false,
  success: false
};

class ContactForm extends Component  {
  state = initialState;

  handleChange = (event) => {
    let target = event.target;

    let stateObject = {};
    stateObject[target.name] = target.value;

    this.setState(stateObject);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let contactForm = document.getElementById('contact-form');
    let formData = new FormData(contactForm);

    fetch(contactForm.getAttribute('action'), {
      method: 'POST',
      body: formData
    }).then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          this.setState(initialState);

          this.setState({
            formSubmitted: true,
            success: true
          });
        } else {
          this.setState({
            formSubmitted: true,
            success: false
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const { handleChange, handleSubmit } = this;
    const { name, email, company, message, success } = this.state;
    return(
      <FormBlock>
        <form name="contact" method="POST" data-netlify="true"
              id="contact-form" data-netlify-honeypot="bee" onSubmit={handleSubmit}>
          <InputStyled
            type="text"
            name="name"
            id="name"
            value={name}
            required
            onChange={handleChange}
            placeholder="Name*"
          />
          <InputStyled
            type="email"
            name="email"
            id="email"
            value={email}
            required
            onChange={handleChange}
            placeholder="Email*"/>
          <InputStyled
            type="text"
            name="company"
            id="company"
            value={company}
            onChange={handleChange}
            placeholder="Company"/>
          <Textarea
            name="message"
            id="message"
            value={message}
            onChange={handleChange}
            placeholder="Message"/>
          <div style={{ display: 'none' }}>
            <input name="bee" />
            <input type="hidden" name="form-name" value="contact" />
          </div>
          <ButtonContainer>
            {success === true ?
              <div>
                <ThankYou>Thank you for your enquiry.</ThankYou>
                <div>Someone from our team will be back to you shortly.</div>
              </div>
              :
              <>
                <Required>
                  *Required Fields
                </Required>
                <Button type="submit" blue>
                  Submit
                </Button>
              </>
            }

          </ButtonContainer>
        </form>
      </FormBlock>
    )
  }
}

export default ContactForm