import React  from "react";
import { Row, Col, Container } from "styled-bootstrap-grid";
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import ExternalLink from "../Core/ExternalLink";
import ContactForm from "./ContactForm";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Background = styled(Container)`
    margin-top: -81px;
    overflow: hidden;
    min-height: 100vh;
    /* height: 100%; */
    @media(min-width: ${breakpoints.lg}){
        background: rgb(0,46,214);
        background: -moz-linear-gradient(90deg, rgba(0,46,214,1) 50%, rgba(255,255,255,1) 50%);
        background: -webkit-linear-gradient(90deg, rgba(0,46,214,1) 50%, rgba(255,255,255,1) 50%);
        background: linear-gradient(90deg, rgba(0,46,214,1) 50%, rgba(255,255,255,1) 50%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#002ed6",endColorstr="#ffffff",GradientType=1);
    }
`;

const ContainerStyled = styled(Container)`
    max-width: none !important;
    @media(min-width: ${breakpoints.lg}){
        padding-top: 241px;
        max-width: 960px !important;
    }
    @media(min-width: ${breakpoints.xl}){
        max-width: 1140px !important;
    }
`;

const Content = styled.div`
    background: ${colors.blue};
    padding: 150px 33px 80px;
    @media(min-width: ${breakpoints.md}){
        padding: 150px 33px 80px;
    }
    @media(min-width: ${breakpoints.lg}){
        background: transparent;
        padding: 0 0 100px 0;
    }

    p {
      font-size: 36px;
      ${fonts.avantGardeBold};
      text-transform: uppercase;
      color: ${colors.white};
      @media(min-width: ${breakpoints.lg}){
          font-size: 44px;
          max-width: 496px;
      }
    }

    b {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-color: ${colors.white};
      -webkit-text-stroke-width: 1px;
      padding-bottom: 40px;
      display: block;
      @media(min-width: ${breakpoints.md}) {
          padding-bottom: 136px;
      }
    }
`;

const ContactEmail = styled(ExternalLink)`
    color: ${colors.white};
    text-decoration: none;
    ${fonts.avantGardeMedium};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
`;

const RowStyled = styled(Row)`
  /* border: 1px solid; */
`

const ContactBlock = ({email, content}) => {
  return (
    <React.Fragment>
      <Background fluid>
        <ContainerStyled>
          <RowStyled>
            <Col lg={6}>
              <Content>
                {documentToReactComponents(content.json)}
                <ContactEmail href={`mailto:${email}`}>{email}</ContactEmail>
              </Content>
            </Col>
            <Col lg={5} lgOffset={1}>
              <ContactForm/>
            </Col>
          </RowStyled>
        </ContainerStyled>
      </Background>
    </React.Fragment>
  )
}

export default ContactBlock
